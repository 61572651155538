<template>
<b-container style="width: 80%; margin: 0 auto;">
  <div id="content" class="slide-up" style="margin-top: 3%; margin-bottom: 3%;">
 <p class="h1">Om ildkule.net</p>
    <p>
    ildkule.net ble utviklet i 2022, da man så behovet for å oppgradere nettsiden som presenterte observasjoner gjort at kameranettverket. Data fra kameranettverket blir nå hentet ut og presentert på en organisert måte,
     og bidrag fra registrerte brukere vil være med på å kvalitetssikre dataen som vises. Nettsiden er en del av Norsk Meteornettverk.
    </p>
<p class="h2">Norsk Meteornettverk</p>
    <p> Norsk meteornettverk holdt stiftelsesmøte på geologisk museum i Oslo 9. mars 2013. Møtet vedtok følgende:<br><br>
<ul class="list-unstyled">
    <h5><b>Formål:</b></h5>
      <li><i class="fa-solid fa-meteor" id="meteor"></i>Arbeide for at alle norske meteoritter gis en åpen og vitenskapelig behandling og gjøres tilgjengelige for forskning og formidling.</li>
      <li><i class="fa-solid fa-meteor" id="meteor"></i>Arbeide for at Naturhistorisk museum, eller tilsvarende vitenskaplige institusjoner i Norge, har førsteretten på å erverve meteoritter fra finnere.</li>
      <li><i class="fa-solid fa-meteor" id="meteor"></i>Opplyse om at meteoritter kan være verdifulle og sikre at finnere gis en seriøs og rettferdig behandling.</li>
      <li><i class="fa-solid fa-meteor" id="meteor"></i>Arbeide for at meteorittene kommer inn under det internasjonale katalogiseringssystemet for meteoritter (The Meteoritical Society).</li>
</ul>
<ul class="list-unstyled">
    <h5><b>Virkemidler:</b></h5>
      <li><i class="fa-solid fa-meteor" id="meteor"></i>Organisere et aktivt nettverk for overvåkning av himmelen med døgnkontinuerlige kameraobservasjoner.</li>
      <li><i class="fa-solid fa-meteor" id="meteor"></i>Etablere kyndige letegrupper for å søke etter observerte nedfall.</li>
      <li><i class="fa-solid fa-meteor" id="meteor"></i>Jobbe for å avklare eiendomsretten til meteoritter og for at det blir en del av naturmangfoldsloven.</li>
      <li><i class="fa-solid fa-meteor" id="meteor"></i>Sikre midler til innkjøp av utstyr for overvåkning av himmelen.</li>
      <li><i class="fa-solid fa-meteor" id="meteor"></i>Betjene norske media med korrekt informasjon.</li>
</ul>
    </p>
</div>
</b-container>
</template>

<script>
export default {
  name: "About",
  components: {
  },
  }; 
</script>


<style scoped>


#meteor {
  margin-right: 16px;
  transform: rotateY(180deg);
}

/* Animation slide up and fade in*/
.slide-up {
  position: relative;
  -webkit-animation-name: slideup;
  -webkit-animation-duration: 1s;
  animation-name: slideup;
  animation-duration: 1s;
}

@-webkit-keyframes slideup {
  from { bottom:-100px; opacity:0 } 
  to { bottom:0px; opacity:1 }
}

@keyframes slideup { 
  from{ bottom:-100px; opacity:0 } 
  to{ bottom:0; opacity:1 }
}

</style>